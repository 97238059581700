<template>
	<div class="info-container">
		<div class="info-top">
			<img src="../index/imgs/right.png" class="check"/>
			<img src="../index/imgs/rightbg.png"  class="checkbg"/>
			<div class="info-content">
				<div class="title">
					提交成功!
				</div>
				<div class="word">
					月底请查收企业微信通知，核对报酬信息
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	metaInfo: {
    meta: [{
      charset: 'utf-8'
    }, {
      name: 'viewport',
      content: 'width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no'
    }]
  },
	data() {
		return {

		}
	}
}
</script>

<style lang="scss" scoped>
@import '../../common/style/success.scss';
</style>